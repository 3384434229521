// hacked examples

import 'ol/ol.css';
import './build/ol-layerswitcher-kannix.css';

import MVT from 'ol/format/MVT';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';

import Map from 'ol/Map.js';
import View from 'ol/View.js';
import LayerGroup from 'ol/layer/Group';
import Link from 'ol/interaction/Link';

import SourceOSM from 'ol/source/OSM';
import OSM, {ATTRIBUTION} from 'ol/source/OSM.js';
import TileLayer from 'ol/layer/Tile.js';

import {fromLonLat, toLonLat} from 'ol/proj.js';

import LayerSwitcher from 'ol-layerswitcher';
import {BaseLayerOptions, GroupLayerOptions} from 'ol-layerswitcher';

import Overlay from 'ol/Overlay.js';

import {apply, applyStyle, stylefunction} from 'ol-mapbox-style';

import seamarkStyle from './build/quantenschaum/kannix.json';
// import spriteData from './build/quantenschaum/icons.json';
// import spriteImage from './build/quantenschaum/icons.png';
// const spriteImageUrl = '/assets/icons.png';

// https://openlayers.org/en/latest/examples/popup.html -----------------------

const container = document.getElementById('popup');
const content = document.getElementById('popup-content');
const closer = document.getElementById('popup-closer');

const overlay = new Overlay({
  element: container,
  autoPan: {
    animation: {
      duration: 250,
    },
  },
});

closer.onclick = function () {
  overlay.setPosition(undefined);
  closer.blur();
  return false;
};

// the layers -------------------------------------------------------------------

// base layers ------------------------------------------------------------------
const osmLayer = new TileLayer({
  title: 'raster OSM',
  type: 'base',
  visible: false,
  source: new OSM({
    attributions: [
      'All maps � <a href="https://openstreetmap.org/">OSM</a>',
      ATTRIBUTION,
    ],
    url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
  }),
});

const cartoLayer = new TileLayer({
  title: 'raster CARTO',
  type: 'base',
  visible: true,
  source: new OSM({
    attributions: [
      'All maps � <a href="https://carto.com/">CARTO</a>',
      ATTRIBUTION,
    ],
    url: 'https://1.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',         
  }),
});

// vector overlay layers ----------------------------------------------------------
const layer = new VectorTileLayer({
  title: 'ol-mapbox-style (TEST)',
  visible: false, 
  declutter: true
});
applyStyle(layer, seamarkStyle);

const vectorQuantenschaumLayer = new VectorTileLayer({
  title: 'quantenschaum (BSH)',
  visible: false,
  source: new VectorTileSource({
    format: new MVT(),
    url:
      'https://tiles.grade.de/quantenschaum/{z}/{x}/{y}.pbf',
    maxZoom: 14,
  }),
});

const tippecanoeSeamarkLayer = new VectorTileLayer({
  title: 'tippecanoe-seamark (OSM)',
  visible: false,
  source: new VectorTileSource({
    format: new MVT(),
    url:
      'https://tiles.grade.de/tippecanoe-seamark/{z}/{x}/{y}.pbf',
    maxZoom: 14,
  }),
});

const tilemakerSeamarkLayer = new VectorTileLayer({
  title: 'tilemaker-seamark (OSM)',
  visible: false,
  source: new VectorTileSource({
    format: new MVT(),
    url:
      'https://tiles.grade.de/tilemaker-seamark/{z}/{x}/{y}.pbf',
    maxZoom: 14,
  }),
});

// raster overlay layers ----------------------------------------------------------
const openSeaMapLayer = new TileLayer({
  title: 'raster-seamark (OSM)',
  visible: true,
  source: new OSM({
    attributions: [
      'All maps � <a href="https://www.openseamap.org/">OpenSeaMap</a>',
      ATTRIBUTION,
    ],
    url: 'https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png',
  }),
});

// the map -----------------------------------------------------------------------------------

const baseMaps = new LayerGroup({
  title: 'base maps',
  layers: [cartoLayer, osmLayer]
});

const overlayMaps = new LayerGroup({
  title: 'overlay maps',
  layers: [openSeaMapLayer, tippecanoeSeamarkLayer, tilemakerSeamarkLayer, vectorQuantenschaumLayer, layer]
});

const map = new Map({
  layers: [baseMaps, overlayMaps],
  overlays: [overlay],
  target: 'map',
  view: new View({
    maxZoom: 18,
    center: fromLonLat([10.018198, 54.670117]),
    zoom: 10,
  }),
});

// apply(map, seamarkStyle);

const layerSwitcher = new LayerSwitcher({
  reverse: true,
  groupSelectStyle: 'group'
});

map.addControl(layerSwitcher);
map.addInteraction(new Link());

// combination of
// https://openlayers.org/en/latest/examples/popup.html
// https://openlayers.org/en/latest/examples/vector-tile-info.html

map.on('singleclick', showInfo);

function showInfo(event) {
  const coordinate = event.coordinate;
  const features = map.getFeaturesAtPixel(event.pixel);
  if (features.length == 0) {
    content.innerText = '';
    content.style.opacity = 0;
    return;
  }
  const properties = features[0].getProperties();
  content.innerText = JSON.stringify(properties, null, 2);
  content.style.opacity = 1;
  overlay.setPosition(coordinate);
}

// stylefunction(tilemakerSeamarkLayer, merikarttaMapboxStyles, 'composite', resolutions, spriteData, spriteImageUrl);
